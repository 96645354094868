<!--
 * @Author: your name
 * @Date: 2022-01-06 15:16:52
 * @LastEditTime: 2022-01-12 18:46:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /iot-product-tool/src/modal/ryProbe/updateProbeCheckModal.vue
-->
<template>
  <div class="update-probe-check-modal">
    <el-container>
      <el-dialog :title="cText.title"
            :visible.sync="isShowModal"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="onCancel"
            center>
            <el-form :inline="false" size="small">
                <el-form-item label="探头ID" required :label-width="formLabelWidth">
                    <el-form-item>
                        <el-input maxlength="9" v-model="probeId" :disabled="!!(editItem && editItem.id)"></el-input>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="质检结果(多选)" required :label-width="formLabelWidth">
                    <el-checkbox v-for="item in tagList" :key="item.id" @change="onchangeCheckbox" :label="item.tagName" v-model="item.selected"></el-checkbox>
                </el-form-item>
                <el-form-item label="责任方(单选)" required :label-width="formLabelWidth">
                    <el-radio :label="4" v-model="responsibleParty" @change="handlingMethod = 0;">英沃</el-radio>
                    <el-radio :label="5" v-model="responsibleParty" @change="handlingMethod = 0;">览宋</el-radio>
                </el-form-item>
                <el-form-item label="处理方式(单选)" required :label-width="formLabelWidth">
                    <el-radio :label="1" v-model="handlingMethod">翻新/维护</el-radio>
                    <el-radio :label="2" v-model="handlingMethod" v-if="responsibleParty == 5">报废</el-radio>
                    <el-radio :label="3" v-model="handlingMethod" v-if="responsibleParty == 4">补新</el-radio>
                </el-form-item>
                <el-form-item label="备注(选填)" :label-width="formLabelWidth">
                    <el-input type="textarea" rows="4" maxlength="50" show-word-limit v-model="remark"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="onCancel" :loading="loading">取 消</el-button>
                <el-button :type="type == 2 ? 'danger' : 'primary'" @click="addOrder" :disabled="!isPass" :loading="loading">{{cText.btnText}}</el-button>
            </div>
        </el-dialog>
    </el-container>
  </div>
</template>
<script>
import { filterProbeOrderStatus } from '@/utils/str';
import { getYMD } from '@/utils/time';
import { Toast, Dialog } from "vant";
export default {
    name: "UpdateProbeCheckModal",
    data() {
        return {
            loading: false,
            title: "",
            formLabelWidth: "120px",
            editItem: {},
            isShowModal: false,
            probeId: '',
            tagList: [],
            tags: [],
            responsibleParty: 0,
            handlingMethod: 0,
            remark: '',
            type: 0
        };
    },
    computed: {
        dialogWidth() {
            return window.screen.width >= 768 ? "50%" : "95%";
        },
        isPass() {
            return (this.probeId.length == 8 || this.probeId.length == 9) && this.responsibleParty > 0 && this.handlingMethod > 0 && this.tags.length;
        },
        cText() {
            let title = '创建质检单'
            let btnText = '确认创建'
            if (this.editItem.id) {
                this.editItem.status == 0 && (title = '请填写质检记录')
                this.editItem.status != 0 && (title = '请确认最终原因')
                btnText = '确认并保存'
            }
            return {
                title,
                btnText
            }
        }
    },

    filters: {
        filterProbeOrderStatus,
        getYMD
    },

    created() {
        const vm = this
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        vm.name = name;
        vm.loadTagList()
    },
    methods: {
        // 新建质检单
        addOrder() {
            const vm = this
            if (vm.editItem.id) {
                vm.onConfirm()
                return
            }
            let msg = '责任方为“览宋”的订单，创建后将归到“待复核”，等待览宋确认'

            if (vm.responsibleParty == 4) {
                msg = `英沃责任，${vm.handlingMethod == 1 ? '翻新' : '补新'}的探头，创建后将归到“已${vm.handlingMethod == 1 ? '确认' : '完成'}”下，等待${vm.handlingMethod == 1 ? '翻新' : '补新'}`
            }
            Dialog.confirm({
                title: "提示",
                message: msg,
                confirmButtonColor: "#32BE32"
            })
            .then(() => {
                vm.onConfirm()
            }).catch(() => {})
        },

        // type: 2修改，1同意
        initData(item, type) {
            const vm = this
            if (type == 1) {
                Dialog.confirm({
                    title: "提示",
                    message: `同意英沃的判定后，订单将转为“${(item.status == 1 && item.handlingMethod == 1) ? '已确认' : '已完成'}”`,
                    confirmButtonColor: "#32BE32"
                })
                .then(() => {
                    vm.onConfirm()
                }).catch(() => {})
            } else {
                vm.isShowModal = true;
            }
            vm.type = type
            if (item.id) {
                item.tagIds && item.tagIds.length ? vm.tagList.map(tItem => {
                    tItem.selected = item.tagIds.indexOf(tItem.id) > -1
                }) : vm.tagList.map(tItem => {
                    tItem.selected = false
                })
                vm.editItem = item
                vm.handlingMethod = item.handlingMethod
                item.status && (vm.remark = item.remark)
                vm.responsibleParty = item.responsibleParty
                vm.tags = [].concat(item.tagIds)
                vm.probeId = item.deviceId
                return
            } else {
                vm.tagList.map(tItem => {
                    tItem.selected = false
                })
                vm.probeId = ''
                vm.editItem = {}
            }
            vm.handlingMethod = 0
            vm.remark = ''
            vm.responsibleParty = 0
            vm.tags = []
        },

        // 更新质检结果
        onchangeCheckbox() {
            const arr = []
            const vm = this
            vm.tagList.map(item => {
                item.selected && arr.push(item.id)
            })
            vm.tags = arr;
            vm.tagList = [].concat(vm.tagList)
        },

        // 保存
        onConfirm() {
            const vm = this;
            let url = 'careInfo/'
            if (vm.editItem.id) {
                url = vm.editItem.status == 0 ? `careInfo/${vm.editItem.id}` : 'careInfo/affirm'
            }
            vm.type == 1 && (url = 'careInfo/affirm')
            vm.ttid = Toast.loading({
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            const data = {
                password: vm.$config.base.password,
                operator: vm.name,
                probeId: vm.probeId,
                careInfoBaseRequest: {
                    handlingMethod: vm.handlingMethod,
                    remark: vm.remark,
                    responsibleParty: vm.responsibleParty,
                    tagIds: vm.tags
                }
            }
            vm.editItem.status && (data.careInfoId = vm.editItem.id)
            vm.type > 0 && (data.confirmStatus = vm.type)
            if (vm.type == 2) {
                vm.ttid.clear()
                let msg = '您未修改责任方和处理方式，是否确认继续？'
                if (vm.responsibleParty != vm.editItem.responsibleParty || vm.handlingMethod != vm.editItem.handlingMethod) {
                    msg = vm.handlingMethod == 1 ?
                    `修改责任方和处理方式后，订单将转为“已${vm.editItem.status == 1 && vm.handlingMethod == 1 ? '确认' : '完成'}”` :
                    `探头处理方式为“${vm.handlingMethod == 3 ? '补新' : '报废'}”的订单，将直接转为“已${vm.editItem.status == 1 && vm.handlingMethod == 1 ? '确认' : '完成'}”`
                }

                Dialog.confirm({
                    title: "是否确认",
                    message: msg,
                    confirmButtonColor: vm.type == 2 ? '#F56C6C' : "#32BE32"
                }).then(() => {
                    vm.updateAction({ url, data })
                }).catch(() => {})
                setTimeout(() => {
                    document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333
                    document.getElementsByClassName('van-overlay')[0].style.zIndex = 3332
                }, 300);
                return
            }
            vm.updateAction({
                url, data
            })
        },

        updateAction(params) {
            const vm = this
            const { url, data } = params
            vm.$http({
                type: vm.editItem.id ? 'PUT' : 'POST',
                url: `${vm.$config.base.PROBE_BASE_URL}${url}`,
                data
            }).then((res) => {
                vm.ttid.clear()
                if (res.code != 1) {
                    return
                }
                vm.isShowModal = false;
                vm.$emit('change')
                Toast.success({
                    message: vm.editItem.id ? (vm.type == 1 ? '操作成功' : '保存成功') : '创建成功'
                })
            }).catch(() => {
                vm.ttid.clear()
            });
        },

        closeModal() {
            this.onCancel()
        },

        // 获取质检标签列表
        loadTagList() {
            const vm = this
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/tags`,
                params: {
                    type: 10
                }
            }).then((res) => {
                vm.tagList = res.data || []
            }).catch(() => { });
        },

        onCancel() {
            this.isShowModal = false
        }
    }
};
</script>
<style lang="less">
@import "@/less/base.less";
.update-probe-check-modal {
    .el-dialog__header {
        button {
            display: none;
        }
    }
    .el-dialog__body {
        padding-top: 0;
        .modal-title {
            padding-bottom: 20px;
            text-align: center;
        }
    }
}
</style>
